<template>
  <div class="changePassword-wrapper">
    <el-form ref="infoForm" :model="infoForm" :rules="rules">
      <el-form-item prop="oldpassword" :label="$t('L_USER.FORM_0007')">
        <el-input
          type="password"
          v-model="infoForm.oldpassword"
          :placeholder="$t('L_USER.TIP_0016')"
          ref="password"
          @focus="handleFocus('oldpassword')"
          @blur="handleBlur('oldpassword')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip1">
          {{ $t("L_USER.TIP_0017") }}
        </div>
      </el-form-item>
      <el-form-item prop="password" :label="$t('L_USER.FORM_0008')">
        <el-input
          type="password"
          v-model="infoForm.password"
          :placeholder="$t('L_USER.TIP_0019')"
          ref="password"
          @focus="handleFocus('password')"
          @blur="handleBlur('password')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip2">
          {{ $t("L_USER.TIP_0020") }}
        </div>
      </el-form-item>
      <el-form-item prop="confirmpassword" :label="$t('L_USER.FORM_0009')">
        <el-input
          type="password"
          v-model="infoForm.confirmpassword"
          :placeholder="$t('L_USER.TIP_0022')"
          ref="password"
          @focus="handleFocus('confirmpassword')"
          @blur="handleBlur('confirmpassword')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip3">
          {{ $t("L_USER.TIP_0023") }}
        </div>
      </el-form-item>
      <el-form-item class="btn-item">
        <el-button
          type="primary"
          native-type="submit"
          @click.native.prevent="updatePassword"
          class="btn"
          >{{ $t("L_USER.BUTTON_0001") }}</el-button
        >
        <div @click="canselUpdatepsd">{{ $t("L_USER.BUTTON_0002") }}</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import { updateUserInfo, getInfo } from "@/api/login";
export default {
  name: "changePassword",
  data() {
    let validateOldPassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_USER.TIP_0018")));
      } else {
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_USER.TIP_0021")));
      } else {
        callback();
      }
    };
    let validateConfirmPassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_USER.TIP_0024")));
      } else if (value !== this.infoForm.password) {
        callback(new Error(this.$t("L_USER.TIP_0024")));
      } else {
        callback();
      }
    };
    return {
      infoForm: {
        oldpassword: "",
        password: "",
        confirmpassword: "",
        userid: "",
        eventid: "",
      },
      rules: {
        oldpassword: [
          {
            required: true,
            validator: validateOldPassword,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        confirmpassword: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "blur",
          },
        ],
      },
      isFormTip1: false,
      isFormTip2: false,
      isFormTip3: false,
    };
  },
  mounted() {
    if (!this.loginStatus) {
      this.$router.push({ path: "/login" });
    } else {
      if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
        this.canselUpdatepsd();
      } else {
        this.$router.push({ path: "/error" });
        return;
      }
    }
  },
  computed: {
    language() {
      let language = this.$i18n.locale;
      return language;
    },
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
  },
  methods: {
    handleFocus(param) {
      this.$refs["infoForm"].clearValidate([param]);
      if (param === "oldpassword") {
        this.isFormTip1 = true;
      } else if (param === "password") {
        this.isFormTip2 = true;
      } else if (param === "confirmpassword") {
        this.isFormTip3 = true;
      }
    },
    handleBlur(param) {
      if (param === "oldpassword") {
        this.isFormTip1 = false;
      } else if (param === "password") {
        this.isFormTip2 = false;
      } else if (param === "confirmpassword") {
        this.isFormTip3 = false;
      }
    },
    updatePassword() {
      this.$refs.infoForm.validate((valid) => {
        if (valid) {
          let param = {
            password: this.infoForm.confirmpassword === "" ? null:this.infoForm.confirmpassword,
            userId: this.infoForm.userid,
            eventid: this.infoForm.eventid,
          };
          updateUserInfo(param)
            .then((res) => {
              if (res.code === 200) {
                this.handleMessaage(
                  "Operation succeeded!",
                  "操作成功！",
                  "success"
                );
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, 1000);
              } else {
                this.handleMessaage(
                  "Operation failed, please try again later!",
                  "操作失败请稍后再试！",
                  "error"
                );
                this.canselUpdatepsd();
              }
            })
            .catch(() => {
              this.handleMessaage(
                "Operation failed, please try again later!",
                "操作失败请稍后再试！",
                "error"
              );
              this.canselUpdatepsd();
            });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
        }
      });
    },
    canselUpdatepsd() {
      this.$refs["infoForm"].resetFields();
      let response = this.userInfo;
      this.infoForm.eventid = response.eventid;
      getInfo().then((res) => {
        if (res.code === 200) {
          this.infoForm.userid = res.user.userId;
        } else {
          this.infoForm.userid = "";
        }
      });
    },
    handleMessaage(text1, text2, type) {
      this.$message({
        message: this.language === "en" ? text1 : text2,
        type: type,
        offset: 60,
      });
    },
  },
};
</script>

<style scoped></style>
