<template>
  <div class="basicInfo-wrapper">
    <el-form ref="infoForm" :model="infoForm" :rules="rules">
      <el-form-item prop="username" :label="$t('L_USER.FORM_0001')">
        <el-input
          type="text"
          v-model="infoForm.username"
          readonly
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item prop="compname" :label="$t('L_USER.FORM_0003')">
        <el-input
          type="text"
          v-model="infoForm.compname"
          :placeholder="$t('L_USER.TIP_0004')"
          ref="compname"
          @focus="handleFocus('compname')"
          @blur="handleBlur('compname')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip2">
          {{ $t("L_USER.TIP_0005") }}
        </div>
      </el-form-item>
      <el-form-item prop="name" :label="$t('L_USER.FORM_0004')">
        <el-input
          type="text"
          v-model="infoForm.name"
          :placeholder="$t('L_USER.TIP_0007')"
          ref="name"
          @focus="handleFocus('name')"
          @blur="handleBlur('name')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip3">
          {{ $t("L_USER.TIP_0008") }}
        </div>
      </el-form-item>
      <el-form-item prop="phone" :label="$t('L_USER.FORM_0005')">
        <el-input
          disabled
          type="text"
          v-model="infoForm.phone"
          :placeholder="$t('L_USER.TIP_0010')"
          ref="phone"
          @focus="handleFocus('phone')"
          @blur="handleBlur('phone')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip4">
          {{ $t("L_USER.TIP_0011") }}
        </div>
      </el-form-item>
      <el-form-item prop="email" :label="$t('L_USER.FORM_0006')">
        <el-input
          type="text"
          v-model="infoForm.email"
          :placeholder="$t('L_USER.TIP_0013')"
          ref="password"
          @focus="handleFocus('email')"
          @blur="handleBlur('email')"
        ></el-input>
        <div class="form_tip" v-if="isFormTip5">
          {{ $t("L_USER.TIP_0014") }}
        </div>
      </el-form-item>
      <el-form-item class="btn-item">
        <el-button
          type="primary"
          native-type="submit"
          @click.native.prevent="updateUser"
          class="btn"
          >{{ $t("L_USER.BUTTON_0001") }}</el-button
        >
        <div @click="canselUpdateUser">{{ $t("L_USER.BUTTON_0002") }}</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import { updateUserInfo, getInfo, getCurrentUser } from "@/api/login";
export default {
  name: "basicInfo",
  data() {
    let validateUserName = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9\u4E00-\u9FA5_]{4,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_USER.TIP_0004")));
      } else {
        return callback();
      }
    };
    let validateCompname = (rule, value, callback) => {
      let reg = /^.{1,40}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_USER.TIP_0006")));
      } else {
        return callback();
      }
    };
    let validateName = (rule, value, callback) => {
      let reg = /^.{2,30}$/;
      if ((value && !reg.test(value))) {
        callback(new Error(this.$t("L_USER.TIP_0009")));
      } else {
        return callback();
      }
    };
    let validatePhone = (rule, value, callback) => {
      let reg = /^((0\d{2,3}\d{7,8})|(1[358479]\d{9}))$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_USER.TIP_0012")));
      } else {
        return callback();
      }
    };
    let validateEmail = (rule, value, callback) => {
      let reg =
        /^[A-Za-z\d]+([_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if ((value && !reg.test(value))) {
        callback(new Error(this.$t("L_USER.TIP_0015")));
      } else {
        return callback();
      }
    };
    return {
      infoForm: {
        username: "",
        compname: "",
        name: "",
        phone: "",
        email: "",
        userid: "",
        eventid: "",
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateUserName,
            trigger: "blur",
          },
        ],
        compname: [
          {
            // required: true,
            validator: validateCompname,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        email: [
          {
            // required: true,
            validator: validateEmail,
            trigger: "blur",
          },
        ],
      },
      isFormTip2: false,
      isFormTip3: false,
      isFormTip4: false,
      isFormTip5: false,
    };
  },
  mounted() {
    this.renderForm();
  },
  computed: {
    language() {
      let language = this.$i18n.locale;
      return language;
    },
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
  },
  methods: {
    renderForm() {
      if (!this.loginStatus) {
        this.$router.push({ path: "/login" });
      } else {
        if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
          let response = this.userInfo;
          this.infoForm.username = response.username;
          this.infoForm.compname = response.compname;
          this.infoForm.name = response.name;
          this.infoForm.phone = response.phone;
          this.infoForm.email = response.email;
          this.infoForm.eventid = response.eventid;
          getInfo().then((res) => {
            if (res.code === 200) {
              this.infoForm.userid = res.user.userId;
            } else {
              this.infoForm.userid = "";
            }
          });
        } else {
          this.$router.push({ path: "/error" });
          return;
        }
      }
    },
    renderForm2() {
      if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
        let response = this.userInfo;
        this.infoForm.username = response.username;
        this.infoForm.compname = response.compname;
        this.infoForm.name = response.name;
        this.infoForm.phone = response.phone;
        this.infoForm.email = response.email;
        this.infoForm.eventid = response.eventid;
        getInfo().then((res) => {
          if (res.code === 200) {
            this.infoForm.userid = res.user.userId;
          } else {
            this.infoForm.userid = "";
          }
        });
        getCurrentUser().then((res) => {
          if (res.code === 200) {
            let data = res.data;
            this.infoForm.compname = data.compname;
            this.infoForm.name = data.name;
            this.infoForm.email = data.email;
          }
        })
      }
    },
    handleFocus(param) {
      this.$refs["infoForm"].clearValidate([param]);
      if (param === "compname") {
        this.isFormTip2 = true;
      } else if (param === "name") {
        this.isFormTip3 = true;
      } else if (param === "phone") {
        this.isFormTip4 = true;
      } else if (param === "email") {
        this.isFormTip5 = true;
      }
    },
    handleBlur(param) {
      if (param === "compname") {
        this.isFormTip2 = false;
      } else if (param === "name") {
        this.isFormTip3 = false;
      } else if (param === "phone") {
        this.isFormTip4 = false;
      } else if (param === "email") {
        this.isFormTip5 = false;
      }
    },
    updateUser() {
      this.$refs.infoForm.validate((valid) => {
        if (valid) {
          let param = {
            compname: this.infoForm.compname === "" ? null:this.infoForm.compname,
            name: this.infoForm.name === "" ? null:this.infoForm.name,
            email: this.infoForm.email === "" ? null:this.infoForm.email,
            userId: this.infoForm.userid,
            eventid: this.infoForm.eventid,
          };
          updateUserInfo(param)
            .then((res) => {
              if (res.code === 200) {
                this.handleMessaage(
                  "Operation succeeded!",
                  "操作成功！",
                  "success"
                );
                this.renderForm2();
              } else {
                this.handleMessaage(
                  "Operation failed, please try again later!",
                  "操作失败请稍后再试！",
                  "error"
                );
                this.renderForm();
              }
            })
            .catch(() => {
              this.handleMessaage(
                "Operation failed, please try again later!",
                "操作失败请稍后再试！",
                "error"
              );
              this.renderForm();
            });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
        }
      });
    },
    canselUpdateUser() {
      this.$refs["infoForm"].resetFields();
      this.renderForm();
    },
    handleMessaage(text1, text2, type) {
      this.$message({
        message: this.language === "en" ? text1 : text2,
        type: type,
        offset: 60,
      });
    },
  },
};
</script>

<style scoped></style>
