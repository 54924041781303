<template>
  <div class="user-wrapper">
    <div class="nav">
      <div class="nav_title">
        <div class="section-img">
          <h3 class="title">{{ $t("L_USER.BANNER_0001") }}</h3>
        </div>
      </div>
      <ul class="nav_header">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="selected === item.id ? 'active' : ''"
          @click="handleClickMenu(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="center-pageCenter">
      <basicInfo ref="basicInfo" v-if="selected === 1"></basicInfo>
      <changePassword
        ref="changePassword"
        v-else-if="selected === 2"
      ></changePassword>
    </div>
  </div>
</template>

<script>
import basicInfo from "@/components/pc/usermanager/basicInfo.vue";
import changePassword from "@/components/pc/usermanager/changePassword.vue";
export default {
  name: "user",
  components: {
    basicInfo,
    changePassword,
  },
  data() {
    return {
      navList: [
        {
          id: 1,
          type: "updateuser",
          name: this.$t("L_USER.TITLE_0001"),
        },
        {
          id: 2,
          type: "updatepassword",
          name: this.$t("L_USER.TITLE_0002"),
        },
      ],
      selected: null,
    };
  },
  mounted() {
    let type = this.$route.query.type;
    if (type) {
      this.renderPageByTypae(type);
    } else {
      this.handleClickMenu(this.navList[0]);
    }
    // 浏览器前进后退事件
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是hashchange事件
    window.addEventListener(
      "hashchange",
      () => {
        if (window.location.hash.includes("id")) {
          let currentPath = window.location.hash.slice(1);
          let index = currentPath.indexOf("id=");
          let result = currentPath.substr(index + 3, currentPath.length);
          this.renderPageByTypae(result);
        }
      },
      false
    );
  },
  watch: {
    "$route.query.type": function (val) {
      this.renderPageByTypae(val);
    },
  },
  methods: {
    handleClickMenu(row) {
      this.selected = row.id;
      this.$router.push({
        name: "usermanager",
        query: {
          id: row.type,
        },
      });
    },
    renderPageByTypae(type) {
      this.navList.forEach((item) => {
        if (type === item.type) {
          this.handleClickMenu(item);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/pc/usermanager/index.scss";
</style>
